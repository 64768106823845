var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}},[_c('div',{staticClass:"card"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.send($event)}}},[_c('div',{staticClass:"card-header"},[_c('span',{staticClass:"headline"},[_vm._v("Kontakt")]),_c('button',{staticClass:"close-btn",on:{"click":_vm.closeContact}})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":"required|min:3","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":"required|min:3","name":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Message","type":"text","rows":"3","error-messages":errors,"required":""},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}],null,true)})],1)]),_c('div',{staticClass:"card-footer"},[_c('v-btn',{staticClass:"send-btn",attrs:{"type":"submit","disabled":invalid},on:{"click":_vm.send}},[_vm._v("Posalji")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }