<template lang="pug">
.error
  | h1 Error
</template>

<script>
export default {
  name: 'Error'
}
</script>
